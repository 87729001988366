import type { ExecutorFunction } from "resourcerer";
import type { ColumnTypes, Connection, Filter, SMAModalItem } from "./types";

import sortBy from "lodash/sortBy";
import sumBy from "lodash/sumBy";
import { useEffect, useRef, useState } from "react";
import { useResources } from "resourcerer";

import Button from "@/components/Button";
import { ErrorState } from "@/components/EmptyState";
import Modal from "@/components/Modal";
import Spinner from "@/components/Spinner";

import useModalContext from "@/js/hooks/useModalContext";
import useNotificationContext from "@/js/hooks/useNotificationContext";
import useSaveReducer from "@/js/hooks/useSaveReducer";
import { Meter } from "@/js/models/MetersCollection";
import SpaceMeterAssignmentsCollection from "@/js/models/SpaceMeterAssignmentsCollection";
import { ClientSpace } from "@/js/models/UnitsCollection";
import { capitalizeWords, pluralize, withUnits } from "@/js/utils/stringFormatter";

import { ApiService, EnhancedSpace, ProrataLevelEnum } from "@/Api/generated";

import { getCustomerFriendlyDataSource, getMeterType } from "../utils";
import AssignmentCanvas from "./AssignmentCanvas";
import AssignmentColumn from "./AssignmentColumn";
import { ColumnConfig } from "./constants";
import { getAreaDisplay } from "./utils";

const getResources: ExecutorFunction<
  "meters" | "spaceMeterAssignments" | "units",
  { propertyToken: string }
> = ({ propertyToken }) => ({
  // the force is because we should always ensure we have the latest meters when we launch this modal.
  // the user may have created or assigned new meters in-app
  meters: {
    force: true,
    params: { include_unassigned_meters: true },
    path: { spaceToken: propertyToken },
  },
  spaceMeterAssignments: { params: { property_token: propertyToken } },
  units: { params: { parent_space__token: propertyToken } },
});

/**
 * Here the user can visually connect/disconnect meters from units. Meter and units are each listed
 * in their own columns, and as they get selected, a <canvas /> element draws connector lines
 * between them.
 */
export default function SpaceMeterAssignmentModal({ property }: { property: EnhancedSpace }) {
  const { hasPrev, prev, next, launch } = useModalContext();

  const [meterScroll, setMeterScroll] = useState<number>(0);
  const [unitScroll, setUnitScroll] = useState<number>(0);
  const [selectedMeters, setSelectedMeters] = useState<string[]>([]);
  const [selectedUnits, setSelectedUnits] = useState<string[]>([]);
  const [hoveredElement, setHoveredElement] = useState<string>(null);

  const [metersAssignedInSession, setMetersAssignedInSession] = useState<string[]>([]);
  const [, forceUpdate] = useState<NonNullable<unknown>>({});

  const [{ isSaving }, saveDispatch] = useSaveReducer();
  const { notify } = useNotificationContext();

  // this is how we will know where to draw our assignment lines to/from on the <canvas />
  const buttonRefs = useRef<{ [id: string]: HTMLButtonElement }>({});

  /**
   * This modal has a lot of ways to filter down which meters/units we're looking at: a tab,
   * a search bar, and categories. But they are all a form of filtering, and we keep track of their
   * state in this single object, which we run our items by to determine which we should show.
   */
  const [filters, setFilters] = useState<{ [K in ColumnTypes]: Filter<K> }>({
    meter: {
      tab: "unassigned",
      search: "",
      // by default categories are empty and so we won't do any filtering
      categories: ColumnConfig.meter.categories.map(({ key }) => ({
        key,
        values: [] as any,
      })),
    },
    unit: {
      tab: "all",
      search: "",
      categories: ColumnConfig.unit.categories.map(({ key }) => ({
        key,
        values: [] as any,
      })),
    },
  });

  const {
    hasLoaded,
    isLoading,
    hasErrored,
    metersCollection,
    unitsCollection,
    spaceMeterAssignmentsCollection,
    invalidate,
  } = useResources(getResources, { propertyToken: property.token });

  // setState wrapper for our filters--tab, search string, and categories for each of
  // meter and unit
  const setFilter =
    <K extends ColumnTypes>(type: K) =>
    (filter: keyof Filter<K>) =>
    (value: any) =>
      setFilters((_filters) => ({ ..._filters, [type]: { ..._filters[type], [filter]: value } }));

  const ulRef = useRef<HTMLUListElement>();

  const allMeters = metersCollection
    .toJSON()
    .map((meter) => mapMeter(meter, spaceMeterAssignmentsCollection));
  const allUnits = unitsCollection
    .toJSON()
    .map((unit) => mapUnit(unit, spaceMeterAssignmentsCollection));
  const getSelectedArea = () =>
    sumBy(
      allUnits.filter(({ id }) => selectedUnits.includes(id)),
      "area",
    );

  // run meters and units through their respective client-side filters
  const filteredMeters = sortBy(filterItems(allMeters, filters.meter), "name");
  const filteredUnits = sortBy(filterItems(allUnits, filters.unit), "name");

  const filteredItemIds = {
    meters: filteredMeters.map(({ id }) => id),
    units: filteredUnits.map(({ id }) => id),
  };

  /**
   * selected{Meters,Units} are across all of our meter and units, but the assignment lines
   * (connections) we want to draw are only for those currently visible--that is, only those
   * selected items within our filtered items list.
   */
  const visibleSelectedMeters = selectedMeters.filter((meterId) =>
    filteredItemIds.meters.includes(meterId),
  );
  const visibleSelectedUnits = selectedUnits.filter((unitId) =>
    filteredItemIds.units.includes(unitId),
  );

  const getCoordinateById = (id: string, scrollPosition: number) =>
    buttonRefs.current[id]?.offsetTop + buttonRefs.current[id]?.offsetHeight / 2 - scrollPosition;

  // these are only connections that we draw--not that we send off to the API.
  // we should only draw those that are in view.
  const connections: Connection[] =
    visibleSelectedMeters.length && visibleSelectedUnits.length ?
      visibleSelectedMeters.flatMap((meterId) =>
        visibleSelectedUnits.map((unitId) => ({
          meterId,
          unitId,
          coords: [getCoordinateById(meterId, meterScroll), getCoordinateById(unitId, unitScroll)],
          highlighted: [meterId, unitId].includes(hoveredElement),
        })),
      )
    : visibleSelectedMeters.length ?
      visibleSelectedMeters.map((meterId) => ({
        meterId,
        coords: [getCoordinateById(meterId, meterScroll), null],
      }))
    : visibleSelectedUnits.map((unitId) => ({
        unitId,
        coords: [null, getCoordinateById(unitId, unitScroll)],
      }));

  /**
   * This is wild, but we can't actually set ref nodes as state and thus respond to nodes being
   * added to/removed from the DOM. We do this easily with single nodes, but lists of nodes cause
   * an infinite loop. No idea why. But in this case the canvas needs to respond to updates when
   * we have a new set of nodes after those nodes have rendered (since it uses that info to draw
   * the lines). So we use this effect to forceUpdate when refs change.
   */
  useEffect(() => {
    forceUpdate({});
  }, [JSON.stringify(filteredItemIds)]);

  const onAssignMeters = () => {
    saveDispatch({ type: "saving" });

    const existingSMAs = spaceMeterAssignmentsCollection
      .toJSON()
      .filter(({ meter_id }) => !selectedMeters.includes("" + meter_id));
    const newSMAs = selectedMeters.map((id) => ({
      prorata_level:
        selectedUnits.length === unitsCollection.length ?
          ProrataLevelEnum.PROPERTY
        : ProrataLevelEnum.UNIT,
      meter_id: +id,
      unit_tokens: selectedUnits,
    }));

    ApiService.apiSpaceMeterAssignmentsCreate({
      requestBody: {
        property_token: property.token,
        assignments: newSMAs,
      },
    })
      .then(() => {
        // Meter {number} [and Meter {number}] has/have, N meters have ...
        const meterMessage =
          selectedMeters.length <= 2 ?
            selectedMeters.map((id) => `Meter ${id}`).join(" and ") +
            (selectedMeters.length === 1 ? " has" : " have")
          : `${selectedMeters.length} meters have`;

        saveDispatch({ type: "reset" });
        notify(`${meterMessage} been assigned to ${withUnits(selectedUnits.length, "unit")}`);

        setMetersAssignedInSession([...new Set(metersAssignedInSession.concat(selectedMeters))]);
        setSelectedMeters([]);
        setSelectedUnits([]);
        spaceMeterAssignmentsCollection.reset([...existingSMAs, ...newSMAs]);
        // make sure when we launch a meter info modal after this that we fetch the latest
        invalidate("meterDetail");
      })
      .catch(() => {
        notify("Meter assignment could not be completed");
      });
  };

  return (
    <Modal
      className="SpaceMeterAssignmentModal"
      footer={
        <>
          {hasLoaded ?
            <Button
              className="assignment-button"
              disabled={!(selectedMeters.length && selectedUnits.length) || isSaving}
              flavor="primary"
              onClick={() => {
                // if we are re-assigning any meters, launch a confirmation modal
                const metersAlreadyAssigned = selectedMeters.filter((meterId) => {
                  const existingSMA = spaceMeterAssignmentsCollection.get(meterId);

                  return (
                    existingSMA &&
                    !existingSMA
                      .get("unit_tokens")
                      .every((unitToken) => selectedUnits.includes(unitToken))
                  );
                });

                if (metersAlreadyAssigned.length) {
                  launch(
                    <ConfirmAssignedMeterModal
                      onConfirmAssignment={onAssignMeters}
                      numberOfMeters={metersAlreadyAssigned.length}
                      numberOfUnits={selectedUnits.length}
                    />,
                  );

                  return;
                }

                onAssignMeters();
              }}
            >
              {isSaving ?
                <Spinner flavor="inline" />
              : null}
              Assign{" "}
              {!selectedMeters.length ?
                "Meters"
              : `${withUnits(selectedMeters.length, "Meter")}${
                  selectedUnits.length ?
                    ` to ${
                      selectedUnits.length === unitsCollection.length ?
                        "whole property"
                      : withUnits(selectedUnits.length, "Unit")
                    } (${getAreaDisplay(getSelectedArea(), property.area_unit)})`
                  : ""
                }`
              }
            </Button>
          : null}
          {hasPrev ?
            <Button flavor="link" onClick={() => prev()}>
              Back
            </Button>
          : null}
          <Button
            flavor="primary"
            onClick={() =>
              next(<CompletionModal numberOfMetersAssigned={metersAssignedInSession.length} />)
            }
          >
            Finish
          </Button>
        </>
      }
      title={`Assign meters for ${property.name}`}
      subtitle={
        "Select the meters and units you’d like to connect together. " +
        "Selecting all units will assign the meters to the whole building. " +
        "If any units are newly onboarded to the property, the meters will also be automatically " +
        "assigned to those units as well."
      }
    >
      <section>
        {isLoading ?
          <Spinner flavor="overlay" />
        : null}
        {hasErrored ?
          <ErrorState />
        : null}
        {hasLoaded ?
          <>
            <AssignmentColumn
              ref={filteredMeters.length ? ulRef : undefined}
              allItems={allMeters}
              setButtonRefs={(id) => (node) =>
                node ? (buttonRefs.current[id] = node) : delete buttonRefs.current[id]
              }
              setScroll={setMeterScroll}
              setHoveredElement={setHoveredElement}
              items={filteredMeters}
              selectedItems={selectedMeters}
              setSelectedItems={(newMeters) => {
                // for any new meters about to be added, auto-add their units if they have already been assigned
                const newAssignedMeterIds = newMeters.filter(
                  (id) => spaceMeterAssignmentsCollection.get(id) && !selectedMeters.includes(id),
                );

                setSelectedMeters(newMeters);

                if (newAssignedMeterIds.length) {
                  setSelectedUnits([
                    ...new Set(
                      selectedUnits.concat(
                        newAssignedMeterIds
                          .flatMap(
                            (id) =>
                              spaceMeterAssignmentsCollection.get(id)?.get("unit_tokens") || [],
                          )
                          // ensure that any existing SMA units are still part of the property
                          .filter((unitToken) => unitsCollection.get(unitToken)),
                      ),
                    ),
                  ]);
                }
              }}
              filters={filters.meter}
              setFilter={setFilter("meter")}
              property={property}
              type="meter"
            />
            <div className="canvas-container" style={{ marginTop: ulRef.current?.offsetTop }}>
              <AssignmentCanvas connections={connections} />
            </div>
            <AssignmentColumn
              ref={!filteredMeters.length ? ulRef : undefined}
              allItems={allUnits}
              setButtonRefs={(id) => (node) =>
                node ? (buttonRefs.current[id] = node) : delete buttonRefs.current[id]
              }
              setScroll={setUnitScroll}
              setHoveredElement={setHoveredElement}
              selectedItems={selectedUnits}
              setSelectedItems={setSelectedUnits}
              items={filteredUnits}
              filters={filters.unit}
              setFilter={setFilter("unit")}
              property={property}
              type="unit"
            />
          </>
        : null}
      </section>
    </Modal>
  );
}

/**
 * Simple modal denoting completion of the SMA process.
 */
export function CompletionModal({ numberOfMetersAssigned }: { numberOfMetersAssigned: number }) {
  const { prev } = useModalContext();

  return (
    <Modal className="SMACompletionModal">
      <img src="/images/meters/sma-completion.svg" />
      <h2>
        {withUnits(numberOfMetersAssigned, "meter")} have been{" "}
        {numberOfMetersAssigned ? " successfully" : ""} assigned to your property
      </h2>
      {numberOfMetersAssigned ?
        <p>Your property analytics and dashboard will be updated with this new data.</p>
      : null}
      <Button flavor="primary" onClick={() => window.location.reload()}>
        Return to property
      </Button>
      <br />
      <Button flavor="link" onClick={prev}>
        Assign more meters
      </Button>
    </Modal>
  );
}

/**
 * This is a confirmation modal that launches only when an existing assigned meter has its assigned
 * units change, to try to limit any confusion and make the change is intentional
 */
export function ConfirmAssignedMeterModal({
  onConfirmAssignment,
  numberOfMeters,
  numberOfUnits,
}: {
  onConfirmAssignment: () => void;
  numberOfMeters: number;
  numberOfUnits: number;
}) {
  const { end } = useModalContext();

  return (
    <Modal
      className="ConfirmAssignedMeterModal"
      footer={
        <>
          <Button onClick={end}>Cancel</Button>
          <Button
            flavor="primary"
            onClick={() => {
              end();
              onConfirmAssignment();
            }}
          >
            Assign {pluralize("Meter", numberOfMeters)}
          </Button>
        </>
      }
      title="Override Meter Assignment"
    >
      <p>You are overriding existing meter assignments with this new assignment.</p>
      <p>
        Are you sure you want to reassign {numberOfMeters === 1 ? "this" : "these"}&nbsp;
        <strong>{withUnits(numberOfMeters, "meter")}</strong> to&nbsp;
        <strong>{withUnits(numberOfUnits, "unit")}</strong>?
      </p>
    </Modal>
  );
}

/**
 * Common function for filtering meters and units based on their respective filter state:
 * the current tab, the search input field, and the checked categories.
 */
export function filterItems<K extends ColumnTypes>(
  allItems: SMAModalItem<K>[],
  filters: Filter<K>,
) {
  return allItems
    .filter(({ tags }) =>
      filters.categories.every((category) => {
        const tag = tags[category.key];

        return (
          !category.values.length ||
          // the category values for a meter/unit can be either a string or an array of strings
          (typeof tag === "string" ?
            category.values.includes(tag)
          : tag?.some((key) => category.values.includes(key)))
        );
      }),
    )
    .filter(({ tab }) => filters.tab === "all" || tab === filters.tab)
    .filter(
      ({ address, title }) =>
        !filters.search ||
        new RegExp(filters.search, "i").test(title) ||
        new RegExp(filters.search, "i").test(address),
    );
}

/**
 * The following two functions take our meter and unit representations and turn them into a
 * consistent UI representation for the Modal to consume. This is especially important in the
 * AssignmentColumns, since they use the same component. This SMAModalItem type has everything
 * it needs to be able to quickly filter an item in or out based on filter state, as well as other
 * common things like title and address. There are also some unique fields for each column type,
 * like meterType or meterTypeDisplayText for meters, and unit_type and area for units.
 */
export function mapMeter(
  meter: Meter,
  smaCollection: SpaceMeterAssignmentsCollection,
): SMAModalItem<"meter"> {
  return {
    id: "" + meter.meter_id,
    title: meter.utility_meter_id,
    address: [meter.address?.street_line_1, meter.address?.street_line_2].filter(Boolean).join(" "),
    meterType: getMeterType(meter),
    meterTypeDisplayText: meter.meter_type_reference?.display_text,
    tab: smaCollection.get(meter.meter_id) ? "assigned" : "unassigned",
    tags: {
      type: capitalizeWords(getMeterType(meter).toLowerCase()),
      utilityProvider: meter.utility_provider?.name,
      // note: only account credentials is an array
      accountCredentials: meter.credential_usernames,
      source: meter.source ? getCustomerFriendlyDataSource(meter.source) : null,
    },
  };
}

export function mapUnit(
  unit: ClientSpace,
  smaCollection: SpaceMeterAssignmentsCollection,
): SMAModalItem<"unit"> {
  return {
    ...unit,
    id: unit.token,
    area: parseInt(unit.area),
    title: unit.name,
    address: [unit.address?.street_line_1, unit.address?.street_line_2].filter(Boolean).join(" "),
    tab: unit.unit_type,
    tags: {
      assignment:
        smaCollection.toJSON().some(({ unit_tokens }) => unit_tokens.includes(unit.token)) ?
          "Assigned"
        : "Unassigned",
    },
  };
}
