import type { ColumnTypes, Filter, SMAModalItem } from "../types";

import { Tooltip } from "antd";
import sumBy from "lodash/sumBy";
import { forwardRef, MutableRefObject, ReactNode, useLayoutEffect, useRef } from "react";

import ButtonGroup from "@/components/Button/ButtonGroup";
import IconButton from "@/components/Button/IconButton";
import Checkbox from "@/components/Checkbox";
import EmptyState from "@/components/EmptyState";
import HovercardToggle from "@/components/HovercardToggle";
import SearchInput from "@/components/Input/SearchInput";
import SvgIcon from "@/components/SvgIcon";
import TextOverflow from "@/components/TextOverflow";

import useOverflow from "@/js/hooks/useOverflow";
import { classnames } from "@/js/utils/cambio";
import { formatPercentage, humanize, withUnits } from "@/js/utils/stringFormatter";

import { EnhancedSpace } from "@/Api/generated";

import { MeterGroups } from "../../constants";
import CategoryFiltersPicker from "../CategoryFiltersPicker";
import { ColumnConfig } from "../constants";
import { getAreaDisplay } from "../utils";

interface AssignmentColumnProps<C extends ColumnTypes> {
  type: C;
  setButtonRefs: (id: string) => (node: HTMLButtonElement) => void;
  setScroll: (scroll: number) => void;
  setHoveredElement: (id: string | null) => void;
  allItems: SMAModalItem<C>[];
  setSelectedItems: (ids: string[]) => void;
  selectedItems: string[];
  filters: Filter<C>;
  /** These are the filtered items that get rendered in the column */
  items: {
    id: string;
    title: string;
    address: string;
  }[];
  /** This update function only updates the filter state for this particular column */
  setFilter: (filter: keyof Filter<C>) => (value: any) => void;
  property: EnhancedSpace;
}

/**
 * The SMA Modal has two assignment columns--one for meters and one for units. Each is a scrollable
 * list of items that can be filtered in various ways: by switching tabs, toggling categories, and
 * typing in a search box. The resulting items can be clicked to get selected, and when there are
 * both selected meters and selected units, the parent component will use a <canvas /> element to
 * draw lines between them.
 */
const AssignmentColumn = forwardRef<HTMLUListElement, AssignmentColumnProps<ColumnTypes>>(
  (
    {
      type = "meter",
      filters,
      allItems,
      setFilter,
      setScroll,
      setHoveredElement,
      setSelectedItems,
      items,
      property,
      selectedItems,
      setButtonRefs,
    },
    ref,
  ) => {
    const { title, tabs, placeholder } = ColumnConfig[type];

    const defaultRef = useRef<HTMLUListElement>();
    const ulRef = (ref || defaultRef) as MutableRefObject<HTMLUListElement>;
    const { onScroll, overflowClassNames } = useOverflow(ulRef);

    const allSelectedItems = selectedItems.map((itemId) =>
      allItems.find(({ id }: any) => id === itemId),
    );

    const totalSelectedArea = type === "unit" ? sumBy(allSelectedItems, "area") : 0;
    const isSelected = ({ id }: { id: string }) => selectedItems.includes(id);

    const getListItem = ({
      id,
      title,
      address,
      meterType,
      meterTypeDisplayText,
      area,
      area_unit,
    }: {
      id: string;
      title: ReactNode;
      address: string;
      meterType?: string;
      meterTypeDisplayText?: string;
      area?: number;
      area_unit?: string;
    }) => {
      const selected = isSelected({ id });

      return (
        <li key={id}>
          <button
            ref={setButtonRefs(id)}
            className={classnames({ selected })}
            onMouseEnter={() => setHoveredElement(id)}
            onMouseLeave={() => setHoveredElement(null)}
            onClick={() =>
              setSelectedItems(
                selected ? selectedItems.filter((item) => item !== id) : selectedItems.concat(id),
              )
            }
          >
            {type === "meter" ?
              getMeterButtonContent({ title, address, meterType, meterTypeDisplayText })
            : getUnitButtonContent({ title, address, area, area_unit, selected, totalSelectedArea })
            }
            {selected ?
              <SvgIcon name="check" />
            : null}
          </button>
        </li>
      );
    };

    useLayoutEffect(() => {
      setScroll(0);
    }, []);

    return (
      <div className="AssignmentColumn">
        <header>
          <h3>{title}</h3>
          <SearchInput
            autocomplete="off"
            label="Search"
            onChange={setFilter("search")}
            placeholder={placeholder}
            value={filters.search}
          />
        </header>
        <ButtonGroup
          items={tabs.map((tab) => ({
            ...tab,
            text: (
              <span
                className={classnames({
                  "has-selected-items": allSelectedItems.some(
                    (item) => tab.key === "all" || item.tab === tab.key,
                  ),
                })}
              >
                {tab.text}
              </span>
            ),
          }))}
          onClick={setFilter("tab")}
          selected={filters.tab}
        />
        <div className="controls">
          <h5>
            {withUnits(items.length, type)}
            {type === "unit" ?
              <span>{getAreaDisplay(sumBy(items, "area"), property.area_unit, "total")}</span>
            : null}
          </h5>
          <Checkbox
            disabled={!items.length}
            checked={items.length && items.every(isSelected)}
            label="Select all"
            onChange={() => {
              const itemIds = items.map((item) => item.id);

              items.every(isSelected) ?
                setSelectedItems(selectedItems.filter((id) => !itemIds.includes(id)))
              : setSelectedItems([...new Set(selectedItems.concat(itemIds))]);
            }}
          />
          <HovercardToggle
            alignment="right"
            contents={() => (
              <CategoryFiltersPicker
                allItems={allItems}
                type={type}
                setFilter={setFilter("categories")}
                selectedCategories={filters.categories}
              />
            )}
          >
            <IconButton
              className={classnames({
                active: !!filters.categories.some(({ values }) => values.length),
              })}
              icon="filter"
            />
          </HovercardToggle>
        </div>
        {items.length ?
          <ul
            ref={ulRef}
            className={overflowClassNames}
            onScroll={(evt) => {
              setScroll((evt.target as HTMLUListElement).scrollTop);
              setHoveredElement(null);
              onScroll();
            }}
          >
            {items.map(getListItem)}
          </ul>
        : <EmptyState
            message={`No matching${
              filters.tab === "all" ? "" : ` ${humanize(filters.tab)}`
            } ${type}s at this property`}
          />
        }
      </div>
    );
  },
);

export default AssignmentColumn;

/**
 * Button content between meters and units are where the columns differ significantly: meters get
 * icons, units get (when selected) a pro rata percentage based on area.
 */
function getMeterButtonContent({
  meterType,
  meterTypeDisplayText,
  title,
  address,
}: {
  meterType: string;
  meterTypeDisplayText: string;
  title: ReactNode;
  address: string;
}) {
  const { icon } = MeterGroups.find(({ key }) => key === meterType) || {};

  return (
    <>
      <Tooltip title={meterTypeDisplayText} arrow={false}>
        <span className="tooltip-content-wrapper">
          {icon ?
            <SvgIcon className="meter-type-icon" name={icon} />
          : null}
          <h5>
            <TextOverflow>{title}</TextOverflow>
          </h5>
        </span>
      </Tooltip>
      <p>{address}</p>
    </>
  );
}

function getUnitButtonContent({
  title,
  address,
  area,
  area_unit,
  selected,
  totalSelectedArea,
}: {
  title: ReactNode;
  address: string;
  area: number;
  area_unit: string;
  selected: boolean;
  totalSelectedArea: number;
}) {
  const selectedAreaPercentage =
    selected && totalSelectedArea ? (area * 100) / totalSelectedArea : 0;

  return (
    <>
      <h5>
        <TextOverflow>{title}</TextOverflow>
      </h5>
      {selected ?
        <span className="prorata-amount">
          <span>
            {formatPercentage(
              selectedAreaPercentage,
              // TODO: should this be the default if no decimal place is specified??
              selectedAreaPercentage < 1 && selectedAreaPercentage > 0 ? 1 : 0,
            )}
          </span>
          <span>{getAreaDisplay(area, area_unit)}</span>
        </span>
      : <p>
          <TextOverflow>{address}</TextOverflow>
        </p>
      }
    </>
  );
}
