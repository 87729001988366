import { useEffect, useState } from "react";
import { useResources } from "resourcerer";

import { Filter } from "@/components/Filters";

import useScreenshotContext, { ScreenshotContextType } from "@/js/hooks/useScreenshotContext";

import {
  PropertyResources,
  useDashboardResources,
} from "@/pages/DashboardPage/DashboardContent/utils";

import createAndDownloadDashboardPdf from "../utils/createAndDownloadDashboardPdf";

export type DownloadOptionsType = {
  dateRange: [string, string];
  organizationToken: string;
  spaceToken: string;
  filters: Filter[];
  organizationId: string;
  spaceId: number;
  organizationName: string;
  spaceName: string;
  isTotalPortfolio: boolean;
};

/*
Provides a hook to download a PDF of the dashboard.
The returned object contains a function to trigger the download (downloadPdf) and a boolean (isGeneratingPdf) to indicate if the PDF is currently being generated.
When downloadPdf is called, all the necessary data is fetched and then the PDF is generated and downloaded.
*/
export const useDashboardPdfDownload = () => {
  const [options, setOptions] = useState<DownloadOptionsType>(null);
  const screenShotContext = useScreenshotContext() as ScreenshotContextType;

  const { hasLoaded: hasConfigsLoaded, featureFlagsModel } = useResources(
    (props) => {
      return {
        featureFlags: {
          params: {
            organization_token: props.organizationToken,
            ...(props.spaceToken ? { property_token: props.spaceToken } : {}),
          },
          dependsOn: !!props.options,
        },
      };
    },
    {
      options,
      spaceToken: options ? options.spaceToken : "",
      organizationToken: options ? options.organizationToken : "",
    },
  );

  const featureConfigurations = featureFlagsModel?.toJSON();

  const placeholderParams = {
    dateRange: ["", ""] as [string, string],
    spaceId: 0,
    filters: [] as Filter[],
    organizationId: "placeholder",
    orgToken: "placeholder",
    space_token: "placeholder",
  }; // placeholders needed for request to be made to certain endpoints. If there is no change in these values no request will be made

  const { hasLoaded, propertyModel } = useDashboardResources(
    hasConfigsLoaded && featureConfigurations && options ?
      ([
        "property",
        "onboardingSummary",
        "meterBreakdown",
        "utilitySpending",
        "onboardingSummary",
        "emissionReduction",
        "weatherData",
        "campus",
        "climateRisks",
        "aggregateCertifications",
        "certifications",
        "energySource",
        "energyConsumption",
      ] as PropertyResources[])
    : [],
    hasConfigsLoaded && featureConfigurations && options ?
      {
        useAnnualizedData:
          options ? !!featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED : undefined,
        dateRange: options.dateRange,
        spaceId: options.spaceId,
        space_token: options.spaceToken,
        filters: options.filters,
        organizationId: options.organizationId,
        featureConfigurations,
        weatherDataNonCritical: false,
      }
    : placeholderParams,
  );

  const { hasLoaded: hasLoadedNeverAnnualized } = useDashboardResources(
    hasConfigsLoaded && featureConfigurations && options ?
      ([
        "scopeBreakdown",
        "totalFootprint",
        "surveyScore",

        "energySource",
        "energyConsumption",
      ] as PropertyResources[])
    : [],
    hasConfigsLoaded && featureConfigurations && options ?
      {
        useAnnualizedData: false,
        dateRange: options.dateRange,
        spaceId: options.spaceId,
        space_token: options.spaceToken,
        filters: options.filters,
        organizationId: options.organizationId,
        featureConfigurations,
        weatherDataNonCritical: false,
      }
    : placeholderParams,
  );

  useEffect(() => {
    if (
      options &&
      hasLoaded &&
      hasLoadedNeverAnnualized &&
      hasConfigsLoaded &&
      featureConfigurations
    ) {
      const checkImage = async (url: string) => {
        try {
          const response = await fetch(url);

          return response.ok; // Returns true if no issue, false otherwise
        } catch {
          return false; // Returns false if there was an issue (CORS, network error, etc.)
        }
      };

      const handleDownload = async () => {
        let isImageValid = undefined;

        if (options.spaceToken) {
          isImageValid = await checkImage(propertyModel.toJSON().image);
        }

        await createAndDownloadDashboardPdf({
          title:
            options.spaceToken ?
              options.spaceName
            : options.organizationName + (options.isTotalPortfolio ? " (Total Portfolio)" : ""),
          downloadOptions: options,
          featureConfigurations,
          screenShotContext: screenShotContext,
          isImageValid,
          isInPortfolioContext: options.isTotalPortfolio,
        });
        setOptions(null);
      };

      handleDownload();
    }
  }, [options, hasLoaded, hasLoadedNeverAnnualized, hasConfigsLoaded]);

  return {
    downloadPdf(downloadOptions: DownloadOptionsType) {
      setOptions({ ...downloadOptions });
    },
    isGeneratingPdf: !!options,
  };
};
