import {
  Document,
  Font,
  Image,
  Page,
  Path,
  pdf,
  StyleSheet,
  Svg,
  Text,
  View,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import uniqueId from "lodash/uniqueId";
import { ApplicationContext } from "pages/_app";

import { AppContextValues } from "@/layouts/AppLayout/AppContext";
import DashboardContent from "@/pages/DashboardPage/DashboardContent";

import { DownloadOptionsType } from "../hooks/useDashboardPdfDownload";
import { ScreenshotContextType } from "../hooks/useScreenshotContext";
import { FeatureFlags } from "../models/types";

Font.register({
  family: "Inter",
  src: "/fonts/inter-var-latin.ttf",
});

Font.register({
  family: "Inter-Bold",
  src: "/fonts/inter-medium-500.ttf",
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
  },
  pageView: { marginTop: 10, paddingLeft: "5%", paddingRight: "5%" },
  header: {
    height: 50,
    backgroundColor: "#00BEB1",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20pt",
  },
  headerText: {
    color: "#FFFFFF",
    fontFamily: "Inter",
  },
  title: {
    fontSize: 16,
  },
  subtitle: {
    fontSize: 8,
    marginTop: 4,
  },
  logo: {
    width: 79.5,
    height: 12,
  },
  text: {
    fontSize: 8,
    fontFamily: "Inter-Bold",
    marginTop: 6,
    fontWeight: "bold",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between", // Space between items
    gap: 10, // Space between cards
    marginTop: 4,
    marginBottom: 4,
  },
  card: {
    flexGrow: 1, // Allow cards to grow
    flexBasis: "0", // Ensure cards take equal space
    height: "160",
  },
  placeholder: {
    flexGrow: 1, // Placeholder occupies the space of a card
    flexBasis: "0",
  },
});

const CambioLogo = () => (
  <Svg style={styles.logo} viewBox="0 0 159 24">
    <Path
      fill="#FFFFFF"
      d="M12.5965 21.0173C7.28783 21.0173 3.35829 17.1939 3.35829 12C3.35829 6.80615 7.28783 2.98272 12.5965 2.98272C15.1816 2.98272 17.3973 3.85796 19.215 5.76392L21.3961 3.65643C19.2496 1.238 16.0875 0 12.4637 0C5.30864 0 0 5.06143 0 12C0 18.9385 5.30864 24 12.4291 24C16.0875 24 19.2496 22.7275 21.3961 20.3148L19.215 18.2016C17.3973 20.1132 15.1816 21.0173 12.5965 21.0173Z"
    />
    <Path
      fill="#FFFFFF"
      d="M39.5031 0.270874H36.1794L25.5334 23.7354H28.9897L31.5749 17.8678L32.752 15.1844L37.824 3.68547L42.8961 15.1844L44.0732 17.8678L44.2001 18.1615L46.6582 23.7354H50.1839L39.5031 0.270874Z"
    />
    <Path
      fill="#FFFFFF"
      d="M82.1339 0.270874H79.3757L69.7048 16.7622L59.8953 0.270874H57.1428V23.7354H60.3684V6.73728L68.8622 20.8851H70.4086L78.9083 6.6394L78.9429 23.7354H82.1627L82.1339 0.270874Z"
    />
    <Path
      fill="#FFFFFF"
      d="M108.504 11.5626C110.35 10.6586 111.66 8.88508 111.66 6.33422C111.66 2.55111 108.637 0.270874 103.23 0.270874H92.6818V23.7354H103.899C109.848 23.7354 112.901 21.4206 112.901 17.3956C112.901 14.2804 111.158 12.2997 108.504 11.5626ZM96.04 2.98296H102.924C106.351 2.98296 108.302 4.25552 108.302 6.73728C108.302 9.21906 106.351 10.4916 102.924 10.4916H96.04V2.98296ZM103.766 21.0175H96.04V13.2095H103.766C107.494 13.2095 109.508 14.4128 109.508 17.1307C109.508 19.8428 107.494 21.0175 103.766 21.0175Z"
    />
    <Path fill="#FFFFFF" d="M121.568 0.270874V23.7354H124.932V0.270874H121.568Z" />
    <Path
      fill="#FFFFFF"
      d="M146.057 0C138.838 0 133.529 5.13052 133.529 12C133.529 18.8695 138.838 24 146.057 24C153.212 24 158.52 18.904 158.52 12C158.52 5.09597 153.212 0 146.057 0ZM146.057 21.0173C140.783 21.0173 136.888 17.1939 136.888 12C136.888 6.80615 140.783 2.98272 146.057 2.98272C151.268 2.98272 155.163 6.80615 155.163 12C155.163 17.1939 151.268 21.0173 146.057 21.0173Z"
    />
  </Svg>
);

interface DashboardPdfProps {
  title: string;
  startDate: string;
  endDate: string;
  screenshots: { [key: string]: string };
  topLeftCard: string;
  hasWaste: boolean;
  hasWater: boolean;
  hasDistrict: boolean;
  hasSurveyScore: boolean;
  hasCertifications: boolean;
  hasAggregateCertifications: boolean;
  hasClimateRisk: boolean;
  property: { space_token: string | null };
}

const DashboardPdf = ({
  title,
  startDate,
  endDate,
  screenshots,
  topLeftCard,
  hasWaste,
  hasWater,
  hasDistrict,
  hasSurveyScore,
  hasCertifications,
  hasAggregateCertifications,
  hasClimateRisk,
  property,
}: DashboardPdfProps) => {
  const maxRowsPerPage = 4;

  // Function to render a row of cards (up to two cards)
  const renderRow = (card1: string, card2?: string) => (
    <View style={styles.rowContainer}>
      <Image src={screenshots[card1]} style={styles.card} />
      {
        card2 ?
          <Image src={screenshots[card2]} style={styles.card} />
        : <View style={styles.placeholder} /> // Placeholder to prevent stretching
      }
    </View>
  );

  // Function to render the header for each page
  const renderHeader = () => (
    <View style={styles.header}>
      <View>
        <Text style={[styles.headerText, styles.title]}>{title}</Text>
        <Text style={[styles.headerText, styles.subtitle]}>
          {startDate} — {endDate}
        </Text>
      </View>
      <CambioLogo />
    </View>
  );

  // Function to render sections into pages with overflow handling
  const renderSections = (
    elements: { content: JSX.Element; isCounted: boolean; pageStart?: number }[],
  ) => {
    const pages: JSX.Element[] = [];
    let currentRows: JSX.Element[] = [];
    let currentCardRowsCount = 0; // The number of rows that contain cards / should be counted for overflow handling

    elements.forEach((element, index) => {
      const currentPage = pages.length + 1;

      // Check if the element specifies a minimum page start
      if (element.pageStart && currentPage < element.pageStart) {
        // Push current rows as a new page if there's content
        if (currentRows.length > 0) {
          pages.push(
            <Page size="LETTER" style={styles.page} key={pages.length}>
              {renderHeader()}
              <View style={styles.pageView}>{currentRows}</View>
            </Page>,
          );
          currentRows = []; // Reset rows for the next page
          currentCardRowsCount = 0; // Reset card row count
        }
      }

      currentRows.push(element.content);
      // Only increment card row count if the element is counted
      // This is to prevent counting non-card elements like text as a part of
      // the allowable number of rows for overflow handling
      if (element.isCounted) currentCardRowsCount++;

      // Create a new page if row count reaches the maximum or it is the last element
      if (currentCardRowsCount === maxRowsPerPage || index === elements.length - 1) {
        pages.push(
          <Page size="LETTER" style={styles.page} key={pages.length}>
            {renderHeader()}
            <View style={styles.pageView}>{currentRows}</View>
          </Page>,
        );
        currentRows = []; // Reset rows for the next page
        currentCardRowsCount = 0; // Reset card row count
      }
    });

    return pages;
  };

  // Function to gather all elements to be rendered
  // This function returns an array of elements with content and properties used for determining overflow handling
  const gatherElements = () => {
    // Array to hold elements, each having content and its properties
    const elements: {
      content: JSX.Element; // JSX element to be rendered
      isCounted: boolean; // Whether this element should be counted for row overflow
      pageStart?: number; // Optional: specifies the minimum page this element can start on
    }[] = [];

    // First page content
    elements.push({ content: renderRow(topLeftCard, ".value-spotlights"), isCounted: true });
    elements.push({ content: <Text style={styles.text}>Carbon</Text>, isCounted: false });
    elements.push({
      content: renderRow(".CarbonEmissionsCard", ".ScopeBreakdownCard"),
      isCounted: true,
    });
    elements.push({ content: <Text style={styles.text}>Energy</Text>, isCounted: false });
    elements.push({ content: renderRow(".EnergyUsageCard", ".EnergySourceCard"), isCounted: true });

    // Climate Resilience Section - combine cards into rows of two
    const climateResilienceCards: string[] = [];

    if (hasSurveyScore) climateResilienceCards.push(".SurveyScoreCard");
    if (property.space_token && hasCertifications)
      climateResilienceCards.push(".CertificationsCard");
    else if (!property.space_token && hasAggregateCertifications)
      climateResilienceCards.push(".AggregatedCertificationsCard");
    if (hasClimateRisk) climateResilienceCards.push(".PhysicalClimateRiskCard");

    // Only add the Climate Resilience title if there are cards to display
    if (climateResilienceCards.length > 0) {
      elements.push({
        content: <Text style={styles.text}>Climate Resilience</Text>,
        isCounted: false,
      });

      for (let i = 0; i < climateResilienceCards.length; i += 2) {
        elements.push({
          content: renderRow(climateResilienceCards[i], climateResilienceCards[i + 1]),
          isCounted: true,
        });
      }
    }

    // Utilities Section - must start on at least the second page
    elements.push({
      content: <Text style={styles.text}>Utilities</Text>,
      isCounted: false,
      pageStart: 2,
    });
    elements.push({
      content: renderRow(".ElectricityCard", ".FuelsCard"),
      isCounted: true,
      pageStart: 2,
    });

    if (hasDistrict && hasWater) {
      elements.push({
        content: renderRow(".DistrictEnergyCard", ".WaterCard"),
        isCounted: true,
        pageStart: 2,
      });
    } else if (hasDistrict) {
      elements.push({ content: renderRow(".DistrictEnergyCard"), isCounted: true, pageStart: 2 });
    } else if (hasWater) {
      elements.push({ content: renderRow(".WaterCard"), isCounted: true, pageStart: 2 });
    }

    if (hasWaste) {
      elements.push({
        content: renderRow(".WasteBreakdownCard", ".UtilitySpendCard"),
        isCounted: true,
        pageStart: 2,
      });
    }

    // Asset Management Section
    elements.push({ content: <Text style={styles.text}>Asset Management</Text>, isCounted: false });
    elements.push({ content: renderRow(".DataCompletenessCard"), isCounted: true });

    return elements;
  };

  const elements = gatherElements();
  const pages = renderSections(elements);

  return <Document>{pages}</Document>;
};

const createAndDownloadDashboardPdf = async ({
  title,
  downloadOptions,
  featureConfigurations,
  screenShotContext,
  isImageValid,
  isInPortfolioContext,
}: {
  title: string;
  screenShotContext: ScreenshotContextType;
  featureConfigurations: FeatureFlags;
  downloadOptions: DownloadOptionsType;
  isImageValid: boolean;
  isInPortfolioContext: boolean;
}) => {
  const screenshotId = uniqueId();
  const formattedStartDate = dayjs(downloadOptions.dateRange[0]).format("MMM DD, YYYY");
  const formattedEndDate = dayjs(downloadOptions.dateRange[1]).format("MMM DD, YYYY");

  // Render content for screenshots
  screenShotContext.renderContentForScreenshots({
    jsx: (
      <ApplicationContext.Provider
        value={
          {
            featureConfigurations,
            organizationName: downloadOptions.organizationName,
            organizationToken: downloadOptions.organizationToken,
            organizationId: downloadOptions.organizationId,
            isInPortfolioContext: downloadOptions.isTotalPortfolio,
          } as AppContextValues
        }
      >
        <DashboardContent dateRange={downloadOptions.dateRange} filters={downloadOptions.filters} />
      </ApplicationContext.Provider>
    ),
    query: {
      space_token: downloadOptions.spaceToken,
      organization_token: downloadOptions.organizationToken,
    },
    width: "1360px",
    height: "1080px",
    screenshotId,
    isImageValid,
  });

  // Wait for content to fully render before taking screenshots
  await new Promise((resolve) => setTimeout(resolve, 500));

  const topLeftCard = downloadOptions.spaceToken ? ".PropertyImageCard" : ".PathToNetZeroCard";

  // Determine which sections to include in screenshots
  const screenshotElements = [
    topLeftCard,
    ".value-spotlights",
    ".CarbonEmissionsCard",
    ".ScopeBreakdownCard",
    ".EnergyUsageCard",
    ".EnergySourceCard",
    ".ElectricityCard",
    ".FuelsCard",
    ".DataCompletenessCard",
    ".UtilitySpendCard",
  ];

  // Conditionally add optional cards based on feature flags
  if (featureConfigurations.ORG_LEVEL_DISTRICT_STEAM_ENABLED) {
    screenshotElements.push(".DistrictEnergyCard");
  }

  if (featureConfigurations.WATER_WASTE_ORG_LEVEL_ENABLED) {
    screenshotElements.push(".WaterCard");
  }

  if (featureConfigurations.ORG_LEVEL_WASTE_ENABLED) {
    screenshotElements.push(".WasteBreakdownCard");
  }

  if (featureConfigurations.CAMBIO_SURVEY_ENABLED) {
    screenshotElements.push(".SurveyScoreCard");
  }

  if (downloadOptions.spaceToken) {
    if (featureConfigurations.CERTIFICATION_AUTOMATION_ENABLED) {
      screenshotElements.push(".CertificationsCard");
    }
  } else {
    if (featureConfigurations.ORG_LEVEL_AGGREGATE_CERTIFICATIONS_ENABLED) {
      screenshotElements.push(".AggregatedCertificationsCard");
    }
  }

  if (featureConfigurations.CLIMATE_RISK_ENABLED) {
    screenshotElements.push(".PhysicalClimateRiskCard");
  }

  // Take screenshots of all required elements
  const screenshots = await screenShotContext.takeScreenshots(screenshotId, screenshotElements);

  // Generate PDF with all screenshots
  const blob = await pdf(
    <DashboardPdf
      title={title}
      startDate={formattedStartDate}
      endDate={formattedEndDate}
      screenshots={screenshots}
      topLeftCard={topLeftCard}
      hasWaste={featureConfigurations.ORG_LEVEL_WASTE_ENABLED}
      hasWater={featureConfigurations.WATER_WASTE_ORG_LEVEL_ENABLED}
      hasDistrict={featureConfigurations.ORG_LEVEL_DISTRICT_STEAM_ENABLED}
      hasSurveyScore={featureConfigurations.CAMBIO_SURVEY_ENABLED}
      hasCertifications={featureConfigurations.CERTIFICATION_AUTOMATION_ENABLED}
      hasAggregateCertifications={featureConfigurations.ORG_LEVEL_AGGREGATE_CERTIFICATIONS_ENABLED}
      hasClimateRisk={featureConfigurations.CLIMATE_RISK_ENABLED}
      property={{ space_token: downloadOptions.spaceToken }}
    />,
  ).toBlob();

  // Save the PDF file
  saveAs(blob, `${title}.pdf`);
};

export default createAndDownloadDashboardPdf;
